// locationsList.js
const locationsList = {
    "beat_the_bomb": [
        "Atlanta", 
        "Brooklyn", 
        "Washington",
    ],
};

// Check if module is available (Node.js environment) and export the variable
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = locationsList;
}
