// NavBarDashboard.js
// React / Chakra Imports
import { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Link,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
// Components
import AdminNavbarLinks from 'components/navbar/NavbarLinksDashboard';
// Filters
import LocationFilter from 'components/filters/LocationFilter';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import routes from 'routes';
import { IoSettingsOutline } from 'react-icons/io5';

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const { secondary, brandText, mini, setMini, theme, setTheme, hovered } =
    props;

  let mainText = useColorModeValue('navy.700', 'white');
  let secondaryText = useColorModeValue('gray.700', 'white');
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  let menuColor = useColorModeValue('gray.400', 'white');
  let navbarPosition = 'fixed';
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const { location, setLocation } = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      w="100%"
      flexDirection={{
        sm: 'column',
        md: 'row',
      }}
      alignItems="center"
      mb={gap}
      justifyContent="flex-start"
    >
      <Box
        position={navbarPosition}
        boxShadow={navbarShadow}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition="center"
        backgroundSize="cover"
        borderRadius="16px"
        borderWidth="1.5px"
        borderStyle="solid"
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
        transition-property="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems="center"
        display={secondary ? 'block' : 'flex'}
        minH="75px"
        justifyContent="flex-start"
        lineHeight="25.6px"
        mx="auto"
        mt={secondaryMargin}
        pb="8px"
        right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
        px={{
          sm: paddingX,
          md: '10px',
        }}
        ps={{
          xl: '12px',
        }}
        pt="8px"
        top={{ base: '12px', md: '16px', xl: '18px' }}
        w={
          mini === false
            ? {
                base: 'calc(100vw - 6%)',
                md: 'calc(100vw - 8%)',
                lg: 'calc(100vw - 6%)',
                xl: 'calc(100vw - 350px)',
                '2xl': 'calc(100vw - 365px)',
              }
            : mini === true && hovered === true
            ? {
                base: 'calc(100vw - 6%)',
                md: 'calc(100vw - 8%)',
                lg: 'calc(100vw - 6%)',
                xl: 'calc(100vw - 350px)',
                '2xl': 'calc(100vw - 365px)',
              }
            : {
                base: 'calc(100vw - 6%)',
                md: 'calc(100vw - 8%)',
                lg: 'calc(100vw - 6%)',
                xl: 'calc(100vw - 185px)',
                '2xl': 'calc(100vw - 200px)',
              }
        }
      >
          <SidebarResponsive routes={routes} theme={theme} setTheme={setTheme} />
          <Box mb={{ sm: '8px', md: '0px' }} flexShrink={0}>
            <Breadcrumb>
              <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
                <BreadcrumbLink href="#" color={secondaryText}>
                  Pages
                </BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem color={secondaryText} fontSize="sm" fontWeight={"600"}>
                <BreadcrumbLink href="#" color={secondaryText} >
                  {brandText}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
            >
              {brandText}
            </Link>
            
          </Box>
          <>
          {/* Flex component for larger screens */}
          <Flex flex="1" justifyContent="flex-end" alignItems="center" mr="20px" display={{ base: 'none', md: 'flex' }}>
            {
              window.location.href.split('/').filter(part => part !== '').pop() !== 'sandbox' &&
              window.location.href.split('/').filter(part => part !== '').pop() !== 'summaries' &&
              window.location.href.split('/').filter(part => part !== '').pop() !== 'spark' && (
                <>
                  <LocationFilter setLocation={setLocation} />
                </>
              )
            }
          </Flex>

          {/* Box for AdminNavbarLinks on larger screens */}
          <Box ms="auto" flexShrink={0} display={{ base: 'none', md: 'flex' }}>
            <AdminNavbarLinks
              mini={mini}
              setMini={setMini}
              theme={theme}
              setTheme={setTheme}
              secondary={props.secondary}
            />
          </Box>

          {/* IconButton and Drawer for small screens */}
          <Box ms="auto" flexShrink={0} display={{ base: 'flex', md: 'none' }}>
            <IconButton
              aria-label="Open filters"
              icon={
                <Icon
                  as={IoSettingsOutline}
                  color={menuColor}
                  w="24px"
                  h="24px"
                />
              }
              onClick={onOpen}
            />
          </Box>

          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent
              w="285px"
              maxW="285px"
              me={{
                sm: '16px',
              }}
              my={{
                sm: '16px',
              }}
              borderRadius="16px"
              bg={sidebarBackgroundColor}
            >
              <DrawerCloseButton />
              <DrawerHeader></DrawerHeader>
              <DrawerBody>
                {
                  window.location.href.split('/').filter(part => part !== '').pop() !== 'sandbox' &&
                  window.location.href.split('/').filter(part => part !== '').pop() !== 'summaries' &&
                  window.location.href.split('/').filter(part => part !== '').pop() !== 'spark' && (
                    <>
                      <LocationFilter setLocation={setLocation} />
                    </>
                  )
                }
                <AdminNavbarLinks
                  mini={mini}
                  setMini={setMini}
                  theme={theme}
                  setTheme={setTheme}
                  secondary={props.secondary}
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      </Box>
    </Flex>
  );
}
