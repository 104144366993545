// Icons.js
// Chakra Imports
import { useColorModeValue } from '@chakra-ui/react';
// Icons
import { Icon, createIcon } from "@chakra-ui/icons";
import { FaCartShopping } from "react-icons/fa6";

export const ShoppingCartIcon = function() {
  return <Icon as={FaCartShopping} />
};

export const XeusCloudLogo = ({ width = "300px", height = "45px", mt="20px", mb="40px", ...props }) => {
  const fillColor = useColorModeValue('black', 'white');

  return (
    <svg
      viewBox="0 0 295 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={{ marginTop: mt, marginBottom: mb }}
      {...props}
    >
      <g clipPath="url(#clip0_145_119)">
        <path
          d="M266.376 33.8697C254.238 31.132 249.309 29.8237 249.309 24.6633C249.309 19.7937 253.655 16.7652 260.647 16.7652C268.391 16.7652 275.748 19.5998 282.521 25.1721L283.541 25.9958L293.738 11.4837L292.864 10.7811C284.002 3.53718 273.538 0.024231 260.865 0.024231C240.958 0.024231 228.115 10.3693 228.115 26.4077C228.115 43.4637 242.269 49.0359 256.107 52.2097C268.076 54.8747 273.83 56.401 273.83 61.8521C273.83 68.8054 266.668 70.2832 260.671 70.2832C251.785 70.2832 243.604 67.1094 237.025 61.0769L236.078 60.2047L224.546 74.3049L225.42 75.0802C234.694 83.2448 245.789 87.0485 260.331 87.0485C281.72 87.0485 295.024 76.7034 295.024 60.0351C295.024 41.9374 280.239 37.0677 266.401 33.8939L266.376 33.8697Z"
          fill={fillColor}
        />
        <path
          d="M51.1534 46.4921L81.6221 0.0484619H57.2957L41.4423 26.7953L25.8073 0.0484619H0.89828L27.3368 42.9791L0 87H24.2536L41.3209 58.3876L58.1697 87L75.1642 46.5648L51.1534 46.4921Z"
          fill={fillColor}
        />
        <path
          d="M99.8547 51.7736H134.499V34.4996H99.8547V17.4436H139.792V0.0484619H88.5413L73.659 34.7419L99.8547 34.4996L65.0889 87H140.569V69.6291H99.8547V51.7736Z"
          fill={fillColor}
        />
        <path
          d="M219.812 0.0484619H198.641V49.6174C198.641 53.736 196.602 66.0919 184.536 65.9223C173.465 65.7769 170.867 57.5881 170.867 49.4236V0.0484619H149.26V49.5447C149.26 73.5054 162.127 87 184.56 87C206.993 87 219.836 73.4812 219.836 49.7385V0.0484619H219.812Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

export const XeusCloudLogoWhite = createIcon({
  displayName: "XeusCloudLogoWhite",
  viewBox: "0 0 295 87",
  path: (
    <g clip-path="url(#clip0_145_119)">
      <path d="M266.376 33.8697C254.238 31.132 249.309 29.8237 249.309 24.6633C249.309 19.7937 253.655 16.7652 260.647 16.7652C268.391 16.7652 275.748 19.5998 282.521 25.1721L283.541 25.9958L293.738 11.4837L292.864 10.7811C284.002 3.53718 273.538 0.024231 260.865 0.024231C240.958 0.024231 228.115 10.3693 228.115 26.4077C228.115 43.4637 242.269 49.0359 256.107 52.2097C268.076 54.8747 273.83 56.401 273.83 61.8521C273.83 68.8054 266.668 70.2832 260.671 70.2832C251.785 70.2832 243.604 67.1094 237.025 61.0769L236.078 60.2047L224.546 74.3049L225.42 75.0802C234.694 83.2448 245.789 87.0485 260.331 87.0485C281.72 87.0485 295.024 76.7034 295.024 60.0351C295.024 41.9374 280.239 37.0677 266.401 33.8939L266.376 33.8697Z" fill="white"/>
      <path d="M51.1534 46.4921L81.6221 0.0484619H57.2957L41.4423 26.7953L25.8073 0.0484619H0.89828L27.3368 42.9791L0 87H24.2536L41.3209 58.3876L58.1697 87L75.1642 46.5648L51.1534 46.4921Z" fill="white"/>
      <path d="M99.8547 51.7736H134.499V34.4996H99.8547V17.4436H139.792V0.0484619H88.5413L73.659 34.7419L99.8547 34.4996L65.0889 87H140.569V69.6291H99.8547V51.7736Z" fill="white"/>
      <path d="M219.812 0.0484619H198.641V49.6174C198.641 53.736 196.602 66.0919 184.536 65.9223C173.465 65.7769 170.867 57.5881 170.867 49.4236V0.0484619H149.26V49.5447C149.26 73.5054 162.127 87 184.56 87C206.993 87 219.836 73.4812 219.836 49.7385V0.0484619H219.812Z" fill="white"/>
    </g>
  ),
});

export const XeusCloudLogoBlack= createIcon({
  displayName: "XeusCloudLogoBlack",
  viewBox: "0 0 295 87",
  path: (
    <g clip-path="url(#clip0_145_119)">
      <path d="M266.376 33.8697C254.238 31.132 249.309 29.8237 249.309 24.6633C249.309 19.7937 253.655 16.7652 260.647 16.7652C268.391 16.7652 275.748 19.5998 282.521 25.1721L283.541 25.9958L293.738 11.4837L292.864 10.7811C284.002 3.53718 273.538 0.024231 260.865 0.024231C240.958 0.024231 228.115 10.3693 228.115 26.4077C228.115 43.4637 242.269 49.0359 256.107 52.2097C268.076 54.8747 273.83 56.401 273.83 61.8521C273.83 68.8054 266.668 70.2832 260.671 70.2832C251.785 70.2832 243.604 67.1094 237.025 61.0769L236.078 60.2047L224.546 74.3049L225.42 75.0802C234.694 83.2448 245.789 87.0485 260.331 87.0485C281.72 87.0485 295.024 76.7034 295.024 60.0351C295.024 41.9374 280.239 37.0677 266.401 33.8939L266.376 33.8697Z" fill="black"/>
      <path d="M51.1534 46.4921L81.6221 0.0484619H57.2957L41.4423 26.7953L25.8073 0.0484619H0.89828L27.3368 42.9791L0 87H24.2536L41.3209 58.3876L58.1697 87L75.1642 46.5648L51.1534 46.4921Z" fill="black"/>
      <path d="M99.8547 51.7736H134.499V34.4996H99.8547V17.4436H139.792V0.0484619H88.5413L73.659 34.7419L99.8547 34.4996L65.0889 87H140.569V69.6291H99.8547V51.7736Z" fill="black"/>
      <path d="M219.812 0.0484619H198.641V49.6174C198.641 53.736 196.602 66.0919 184.536 65.9223C173.465 65.7769 170.867 57.5881 170.867 49.4236V0.0484619H149.26V49.5447C149.26 73.5054 162.127 87 184.56 87C206.993 87 219.836 73.4812 219.836 49.7385V0.0484619H219.812Z" fill="black"/>
    </g>
  ),
});

export const XeusCloudLogoCover = createIcon({
  displayName: "XeusCloudLogoCover",
  viewBox: "0 0 435 129",
  path: (
    <g width='435' height='129' viewBox='0 0 435 129' fill='none'>
      <g clip-path='url(#clip0_145_150)'>
      <path d="M435 88.9457C435 113.661 415.382 129 383.842 129C362.363 129 346.002 123.36 332.363 111.254L331.074 110.104L348.079 89.1972L349.475 90.4904C359.177 99.4353 371.241 104.141 384.344 104.141C393.186 104.141 403.747 101.986 403.747 91.6399C403.747 83.5572 395.263 81.2941 377.613 77.3425C357.208 72.6007 336.336 64.3743 336.336 39.0844C336.336 15.3392 355.31 -0.0359268 384.63 -0.0359268C403.317 -0.0359268 418.747 5.17293 431.814 15.9139L433.103 16.9557L418.067 38.4737L416.563 37.2523C406.575 28.99 395.728 24.787 384.308 24.787C373.998 24.787 367.59 29.2774 367.59 36.4979C367.59 44.1495 374.857 46.0894 392.757 50.1487C413.198 54.8187 434.964 62.0393 434.964 88.9098L435 88.9457ZM75.4296 68.9365L120.358 0.0718426H84.4869L61.1098 39.731L38.0549 0.0718426H1.32458L40.3103 63.7276L0 129H35.7637L60.9308 86.5388L85.7757 129L110.8 69.0443L75.3938 68.9365H75.4296ZM147.243 76.7678H198.329V51.1546H147.243V25.8647H206.134V0.0718426H130.561L108.616 51.4779L147.243 51.1546L95.9785 129H207.243V103.207H147.243V76.7678ZM324.129 0.0718426H292.912V73.5706C292.912 79.6775 289.905 97.9983 272.112 97.7469C255.788 97.5313 251.957 85.3893 251.957 73.2832V0.0718426H220.095V73.4628C220.095 108.991 239.069 129 272.148 129C305.227 129 324.165 108.955 324.165 73.7502V0.0718426H324.129Z" fill="url(#paint0_linear_145_150)"/>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_145_150'
          x1='0'
          y1='64.482'
          x2='435'
          y2='64.482'
          gradientUnits='userSpaceOnUse'>
          <stop stop-color='#5158FF' />
          <stop offset='1' stop-color='#00E5D1' />
        </linearGradient>
        <clipPath id='clip0_145_150'>
          <rect width='435' height='129' fill='white' />
        </clipPath>
      </defs>
    </g>
  ),
});
