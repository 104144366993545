// // Scrollbar.js
// // React Imports
// import { Box, Flex } from '@chakra-ui/react';

// // renderTrack
// export const renderTrack = ({ style, ...props }) => {
//   const trackStyle = {
//     position: 'absolute',
//     maxWidth: '100%',
//     width: 6,
//     transition: 'opacity 200ms ease 0s',
//     opacity: 0,
//     background: 'transparent',
//     bottom: 2,
//     top: 2,
//     borderRadius: 3,
//     right: 0,
//   };
//   return <div style={{ ...style, ...trackStyle }} {...props} />;
// };

// // renderThumb
// export const renderThumb = ({ style, ...props }) => {
//   const thumbStyle = {
//     borderRadius: 15,
//     background: 'rgba(222, 222, 222, .1)',
//   };
//   return <div style={{ ...style, ...thumbStyle }} {...props} />;
// };

// // renderView
// export const renderView = ({ style, ...props }) => {
//   const viewStyle = {
//     marginBottom: -22,
//     maxWidth: '100%',
//   };
//   return (
//     <Flex
//       direction="column"
//       align="center"
//       me={{ base: '0px !important', lg: '-16px !important' }}
//       style={{ ...style, ...viewStyle }}
//       {...props}
//     />
//   );
// };

// // renderViewMini
// export const renderViewMini = ({ style, ...props }) => {
//   const viewStyle = {
//     marginBottom: -22,
//     maxWidth: '100%',
//   };
//   return (
//     <Flex
//       direction="column"
//       align="center"
//       w="100%"
//       me={{ base: '0px !important', lg: '0px !important' }}
//       style={{ ...style, ...viewStyle }}
//       {...props}
//       overflow="hidden !important"
//     />
//   );
// };


// export const messagesRenderTrack = ({ style, ...props }) => {
//   const trackStyle = {
//     position: 'absolute',
//     maxWidth: '100%',
//     width: 6,
//     transition: 'opacity 200ms ease 0s',
//     opacity: 0,
//     background: 'transparent',
//     bottom: 2,
//     top: 2,
//     borderRadius: 3,
//     right: 0,
//   };
//   return <div style={{ ...style, ...trackStyle }} {...props} />;
// };
// export const messagesRenderThumb = ({ style, ...props }) => {
//   const thumbStyle = {
//     borderRadius: 15,
//     background: 'rgba(222, 222, 222, .1)',
//   };
//   return <div style={{ ...style, ...thumbStyle }} {...props} />;
// };
// export const messagesRenderView = ({ style, ...props }) => {
//   const viewStyle = {
//     marginBottom: -22,
//     maxWidth: '100%',
//   };
//   return <Box style={{ ...style, ...viewStyle }} {...props} />;
// };