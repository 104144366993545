import { extendTheme } from "@chakra-ui/react";
// import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button"; 
import { inputStyles } from "./components/input";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";

const config = {
  initialColorMode: "dark", // Set the default color mode to dark
  useSystemColorMode: false,
};

export default extendTheme(
  { config, breakpoints },
  globalStyles,
  buttonStyles, // button styles
  inputStyles, // input styles
  // CardComponent // card component
);
