export const columnsDataOverview = [
  {
    Header: "PRODUCT",
    accessor: "product",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "PRICE",
    accessor: "price",
  },
];
