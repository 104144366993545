// routes.js
// React Imports
import React from 'react';
// Pages
import Products from 'views/dashboard/products/index';

// Icons
import {
  ShoppingCartIcon,
} from "components/icons/Icons";

const routes = [
  {
    name: 'Products',
    layout: '/dashboard',
    path: '/products',
    icon: <ShoppingCartIcon color="inherit" />,
    component: <Products />,
  },
];

export default routes;