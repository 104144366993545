// NavBarLinksDashboard.js
// Chakra Imports
import {
  Avatar,
  Tooltip,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button
} from '@chakra-ui/react';
// Icons
import { SunIcon, MoonIcon, DownloadIcon } from '@chakra-ui/icons';
// AuthContext
import { useAuth } from 'AuthContext';
// PDF Exporter Library
import { capitalizeWords } from 'utils/dataFormatter';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function HeaderLinks(props) {
  const { secondary } = props;
  const { email, firstName, lastName, logout, brandText } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  let menuBg = useColorModeValue('white', 'navy.800');

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const { reportTemplateRef } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  const onClose = () => setIsOpen(false);

  const handleLogoutClick = () => {
    setIsOpen(true);
  };

  const confirmLogout = () => {
    logout();
    onClose();
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      justifyContent="space-around"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
      mt={{ base: '10px', md: '0' }}
    >      
      <Tooltip label={`Enable ${colorMode === 'dark' ? 'light' : 'dark'} mode`}>
        <IconButton
          aria-label="Toggle theme"
          icon={colorMode === 'dark' ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
          variant="ghost"
          mr={2}
        />
      </Tooltip>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name={`${firstName} ${lastName}`}
            bg="#5158FF"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px" direction="column">
            <Text
              ps="20px"
              pt="16px"
              w="100%"
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              {capitalizeWords(`${firstName} ${lastName}`)}
            </Text>
            <Text
              ps="20px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="xs"
              color={textColor}
            >
              {email}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              transition="all 0.2s ease-in-out"
            >
              <Text fontSize="sm" onClick={handleLogoutClick}>Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Logout
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to log out?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="teal" onClick={confirmLogout} ml={3}>
                Log out
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}